import axios from 'axios'
import store from '@/store/index.js'
import baseURL from './baseUrl'
import { Message } from 'element-ui'
const http = {}
var instance = axios.create({
    timeout: 10000,
    baseURL,
    validateStatus(status) {
        switch (status) {
            case 400:
                Message.error('请求出错')
                break
            case 401:
                Message.warning({
                    message: '授权失败，请重新登录'
                })
                store.commit('LOGIN_OUT')
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
                return
            case 403:
                Message.warning({
                    message: '拒绝访问'
                })
                break
            case 404:
                Message.warning({
                    message: '请求错误,未找到该资源'
                })
                break
            case 500:
                Message.warning({
                    message: '服务端错误'
                })
                break
        }
        return status >= 200 && status < 300
    }
})

// 添加请求拦截器
instance.interceptors.request.use(
    function (config) {
        config.headers.villageId = 279
        return config
    },
    function (error) {
        Message.error('请求超时')
        return Promise.reject(error)
    }
)

// 响应拦截器即异常处理
instance.interceptors.response.use(
    response => {
        return response.data
    },
    err => {
        return Promise.reject(err.response)
    }
)

http.get = function (url, options) {

    return new Promise((resolve, reject) => {
        instance({
            url: url,
            method: 'get',
            params: options
        })
            .then(response => {
                if (response.code == 0 || response.code === 200) {
                    resolve(response.data)
                } else if (response.code == 401) {
                    Message.warning({
                        message: '授权失败，请重新登录'
                    })
                    // store.commit('LOGIN_OUT')
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 1000)
                } else {
                    Message.error({
                        message: response.msg
                    })
                    reject(response.msg)
                }
            })
            .catch(e => {
                Message.error(e)
            })
    })
}

http.post = function (url, data, options) {
    return new Promise((resolve, reject) => {
        instance
            .post(url, data, options)
            .then(response => {
                if (response.code === 0 || response.code === 200) {
                    resolve(response.data)
                } else if (response.code == 401) {
                    Message.warning({
                        message: '授权失败，请重新登录'
                    })
                    // store.commit('LOGIN_OUT')
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 1000)
                } else {
                    Message.error({
                        message: response.message
                    })
                    reject(response.message)
                }
            })
            .catch(e => {
                Message.error(e)
            })
    })
}

export default http
