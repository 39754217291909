import axios from '@/config/httpConfig'
// let ts='taishun/uac/pub/'
let ts='pub/'
//let ts=''
const imageTextpage = (params) => axios.post('taishun/uac/pub/uac/bi/party/building/imageText/page', params) //BI大屏-党员学习
const industrybusiness1 = (params) => axios.post(ts+'uac/bi/industry/business/data', params) //BI大屏-产业经营数据
const industrybusiness2 = (params) => axios.post(ts+'uac/bi/industry/base/data', params) //BI大屏-产业经营数据
const industrynjl = (params) => axios.post('farmhouse/pub/consumer/farmhouse/detail', params) //BI大屏-产业农家乐
const industryyl = (params) => axios.get('entertainment/pub/entertainment/detail', params) //BI大屏-产业娱乐
const industryms = (params) => axios.post('hs/consumer/pub/consumer/homestay/storeDetail', params) //BI大屏-产业民宿
const industryncp = (params) => axios.post(ts+'uac/bi/industry/shop/detail', params) //BI大屏-产业农产品
const industrtravelday = (params) => axios.post('touristroute/pub/consumer/route/days', params) //BI大屏-产业线路日期
const industrtravellist = (params) => axios.post('touristroute/pub/consumer/project/today/list', params) //BI大屏-产业线路列表
const industrtraveldetail = (params) => axios.post('touristroute/pub/consumer/route/detail', params) //BI大屏-产业线路详情
const trafficpersonlist = (params) => axios.post(ts+'uac/bi/traffic/grid/person/list', params) //BI大屏-交通网格员
const trafficstopdata = (params) => axios.post(ts+'uac/bi/traffic/illegal/stop/warn/data', params) //BI大屏-违停报警数据(实时/记录)
const trafficstopdetail = (params) => axios.post(ts+'uac/bi/traffic/warn/detail', params) //BI大屏-违停报警数据(实时/记录)
const trafficstopimage = (params) => axios.post(ts+'uac/hik/events/image', params) //BI大屏-违停报警数据(实时/记录)
const cultureanalysis = (params) => axios.post(ts+'uac/bi/culture/appointment/analysis', params) //BI大屏-文化资产预约分析数据
const culturedetail = (params) => axios.post(ts+'uac/bi/culture/assets/detail', params) //BI大屏-文化资产预约分析数据
const healthdata = (params) => axios.post(ts+'uac/bi/health/base/data', params) //BI大屏-健康基础数据

const partyoverview = (params) => axios.post(ts+'uac/bi/party/building/overview', params) //BI大屏-党建统领数据概览
const partymemberdetail = (params) => axios.post(ts+'uac/bi/party/building/party/member/detail', params) //BI大屏-党员详细信息
const partylowincomepage = (params) => axios.post(ts+'uac/bi/party/building/low/income/marginal/page', params) //BI大屏-低保特困户人员分页
const partylowincomedetail = (params) => axios.post(ts+'uac/bi/party/building/low/income/detail', params) //BI大屏-低保特困户人员详情
const partybiImageTextdetail = (params) => axios.post(ts+'uac/bi/party/building/biImageText/detail', params) //BI大屏-文章内容详情
const neighborbase = (params) => axios.post(ts+'uac/bi/neighbor/base/data', params) //BI大屏-邻里页面基础数据
const wisdombase = (params) => axios.post(ts+'uac/bi/wisdom/base/data', params) //BI大屏-智慧页面基础数据
const wisdomdevicelist = (params) => axios.post(ts+'uac/bi/wisdom/device/list', params) //BI大屏-设备列表
const wisdomdevicedetail = (params) => axios.post(ts+'uac/bi/wisdom/device/detail', params) //BI大屏-设备详情
const lowCarbonbase = (params) => axios.post(ts+'uac/bi/lowCarbon/base/data', params) //BI大屏-低碳页面基础数据
const lowCarbondevicelist = (params) => axios.post(ts+'uac/bi/lowCarbon/device/warn/record', params) //BI大屏-低碳页面设备报警记录
const lowCarbondevicedetail = (params) => axios.post(ts+'uac/bi/lowCarbon/environment/monthly/qualify/list', params) //BI大屏-低碳页面设备月达标情况
const getvido =  (params) => axios.get(ts+'uac/bi/wisdom/getPreviewUrl', params) //视频获取流
const hiktree = (params) => axios.post(ts+'uac/hik/camera/tree', params) //BI大屏-治理列表
const hikpage = (params) => axios.post(ts+'uac/hik/camera/page', params) //BI大屏-治理列表
const hikdetail = (params) => axios.post(ts+'uac/hik/camera/detail', params) //BI大屏-治理详情
const hikbyDeviceId = (params) => axios.post(ts+'uac/hik/camera/detail/byDeviceId', params) //设备ID查监控
const hikcontrol = (params) => axios.post(ts+'uac/hik/camera/control', params) //高空AR监控进行云台操作
const hikwaterlist = (params) => axios.post(ts+'uac/hik/water/list', params) //水位监测数据
const industrygerFlow = (params) => axios.post(ts+'uac/bi/industry/passengerFlow', params) //BI大屏-客流数据
export {
    industrybusiness1,industrybusiness2,trafficstopimage,
    industrynjl,industryyl,industryms,industryncp,industrtraveldetail,industrtravelday,industrtravellist,
    trafficpersonlist,trafficstopdata,trafficstopdetail,
    cultureanalysis,culturedetail,healthdata,
    partyoverview, partymemberdetail, partylowincomepage, partylowincomedetail, partybiImageTextdetail,
    neighborbase,imageTextpage,
    wisdombase, wisdomdevicelist, wisdomdevicedetail,
    lowCarbonbase, lowCarbondevicelist, lowCarbondevicedetail,
    getvido,hikpage,hikdetail,hikbyDeviceId,hikcontrol,hikwaterlist,
    industrygerFlow,hiktree
}
